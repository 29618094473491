<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardBody>
          <!-- ======= Pricing Section ======= -->
          <section id="pricing" class="pricing section-bg">
              <div class="container">
                  <div class="section-title">
                    <h2>{{ $t('Package') }}</h2>
                    <p>{{ $t('PackageDescription') }}</p>
                  </div>
                  <div class="row no-gutters">
                      <div class="col-lg-3 box"
                           v-for="(plan, index) in plans"
                           :key="'plan-'+plan.id"
                           :id="'plan-'+plan.id">
                          <h3>{{ plan.name }}</h3>
                          <h4>¥{{ plan.price.quantity }}<span>{{ $t('Monthly') }}</span></h4>
                          <ul role="list-items" class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('Containsthenumberofdomains') }} <span class="badge badge-success badge-pill"> {{ plan.meta.DOMAIN }} </span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('Upgradabledomainnamelimit') }} <span class="badge badge-success badge-pill"> {{ plan.meta.DOMAIN_UP }}</span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('CCtrafficattackprevention') }} <span class="badge badge-primary badge-pill"> {{ plan.meta.CC }}</span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('DDOSattackprevention') }} <span class="badge badge-primary badge-pill"> {{ plan.meta.DDOS }}</span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('Websitefirewall') }} <span class="badge badge-success badge-pill"> {{ plan.meta.WAF }}</span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('Genericdomainname') }} <span class="badge badge-primary badge-pill"> {{ plan.meta.WILDDOMAIN }} </span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">{{ $t('Traffic') }} <span class="badge badge-primary badge-pill"> {{ plan.meta.TRAFFIC }} </span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('FreeTraffic') }} <span class="badge badge-primary badge-pill">{{ plan.meta.EXTRA_TRAFFIC }}</span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> IPV6 <span class="badge badge-primary badge-pill"> {{ plan.meta.IPV6 }}</span></li>
                            <li class="list-group-item d-flex justify-content-between align-items-center"> {{ $t('ServiceSupport') }} <span class="badge badge-primary badge-pill"> {{ plan.meta.SUPPORT }}</span></li>
                          </ul>

                          <a class="btn-buy"
                             v-if="plan.price.quantity > 0"
                             @click="clickPlan(plan)">
                            {{ $t('Select') }}
                          </a>

                      </div>

                  </div>

                  <div class="box">
                    <h3>{{ $t('Value-addedservices') }}</h3>
                      <ul role="list-items" class="list-group">
                          <li class="list-group-item d-flex align-items-center">
                            {{ $t('Accelerateddomainname') }} <span class="badge badge-info badge-pill"> {{ $t('Effectivemonthly') }} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <button data-shoppy-product="13rtGMV" class="btn badge badge-success badge-pill"> 1 / $30</button>&nbsp;&nbsp;
                          </li>
                          <!--<li class="list-group-item d-flex align-items-center">
                            {{ $t('Highspeedandhighflowprevention') }} <span class="badge badge-info badge-pill"> {{ $t('Effectivemonthly') }} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <button data-shoppy-product="4tQ2kdN" class="btn badge badge-success badge-pill"> 1G / ¥3</button>&nbsp;&nbsp;
                              <span class=" badge badge badge-pill"> {{ $t('AcceptPureDownloadTraffic') }} </span>&nbsp;&nbsp;
                          </li>-->
                      </ul>
                      <CRow class="text-left">
                        <CCol sm="6">
                          <CInput :label="$t('AdditionalDomainNumber')"
                                  type="number"
                                  placeholder="Enter domain number"
                                  v-model="additionalDomainNumber"
                                  v-if="selectedPlan && selectedPlan.price.quantity > 0"/>
                          <a href="" @click.prevent="clickContactConsultant()" class="btn btn-primary mt-10">{{ $t('ContactCustomerService') }}</a>
                        </CCol>
                        <CCol sm="6"
                              class="text-right price-wrapper">
                          <span id="price"><b>{{ $t('Price') }} </b> : $ {{ amountPrice }}</span>
                          <CButton size='lg'
                                   color='primary'
                                   @click="clickSubmit()">
                            {{ $t('Submit') }}
                          </CButton>
                        </CCol>
                      </CRow>
                  </div>
              </div>
          </section><!-- End Pricing Section -->
          <!-- ======= Contact Section ======= -->
          <section id="contact" class="contact">
              <div class="container">
                  <div class="section-title">
                    <h2>{{ $t('ContactConsulting') }}</h2>
                      <p></p>
                  </div>
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="row">
                              <div class="col-md-4">
                                  <div class="info-box mt-4">
                                      <i class="bx bx-envelope"></i>
                                      <h3>Telegram</h3>
                                      <p><a href="https://t.me/ac_ludwig">Telegram:@ac_ludwig</a></p>
                                  </div>
                              </div>
                              <div class="col-md-4">
                                  <div class="info-box mt-4">
                                      <i class="bx bx-envelope"></i>
                                      <h3>Email</h3>
                                      <p>
                                          <a href="mailto:sales@asians.cloud">Email: sales@asians.cloud</a>
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section><!-- End Contact Section -->
        </CCardBody>
      </CCard>
      <CModal :title="$t('checkout.CheckoutConfirmation')"
              :show.sync="showCheckoutModal"
              :closeOnBackdrop="false">
        <CRow>
          <CCol col="6">
            <label class="text-value">
              {{ $t('checkout.CNAME') }}
            </label>
            <p>{{ checkout.cname }}</p>
          </CCol>

          <CCol col="6">
            <label class="text-value">
              {{ $t('checkout.PlanName') }}
            </label>
            <p>{{ checkout.plan.name }}</p>
          </CCol>

          <CCol col="6">
            <label class="text-value">
              {{ $t('checkout.AdditionalDomainNumber') }}
            </label>
            <p>{{ checkout.additional_domain_number }}</p>
          </CCol>

          <CCol col="6">
            <label class="text-value">
              {{ $t('checkout.AdditionalTraffic') }}
            </label>
            <p>{{ checkout.additional_traffic }}</p>
          </CCol>

          <CCol col="6">
          </CCol>
          <CCol col="6">
            <label class="text-value">
              {{ $t('checkout.TotalPrice') }}
            </label>
            <p>{{ checkout.amount_currency1 }} USDT</p>
          </CCol>

          <CCol col="12">
          <p>{{ $t('checkout.CheckoutNote') }}</p>
          </CCol>
        </CRow>

        <template v-slot:footer>
          <button
            type="button"
            class="btn btn-primary"
            @click="clickConfirm()">
            {{ $t('Pay') }}
          </button>
        </template>
      </CModal>
      <CModal :title="$t('checkout.SelectPaymentMethod')"
              :show.sync="showModal"
              :closeOnBackdrop="false">
        <input type="radio"
               id="cryptocurrency"
               name="payment_type"
               value="cryptocurrency"
               v-model="selectedPaymentType">
        <label for="cryptocurrency">{{ $t('checkout.Cryptocurrency') }}</label>
        <ul id="coins" :class="{Disabled: selectedPaymentType !== 'cryptocurrency'}">
          <li v-for="coin in coinSupports"
              :key="'coin-' + coin.id"
              @click="clickCoins(coin.code, $event)">
            <div class="box"></div>
            <img :src="coin.icon"/>
            <p>{{ coin.name }}</p>
          </li>
        </ul>
        <input type="radio"
               id="accountbalance"
               name="payment_type"
               value="account_balance"
               v-model="selectedPaymentType">
        <label for="accountbalance">{{ $t('checkout.AccountBalance') }}</label>
        <p>{{ $t('checkout.YourBalance') }}: <b :class="{BalanceDanger: parseFloat($store.state.balance) < parseFloat(checkout.amount_currency1)}">{{ $store.state.balance }}</b></p>
        <template v-slot:footer>
          <button
              type="button"
              class="btn btn-secondary"
              @click="showModal=false">
            {{ $t('Cancel') }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="clickPay()">
            {{ $t('Pay') }}
          </button>
        </template>
      </CModal>
    </CCol>
  </CRow>
</template>


<script>
import 'boxicons'
import 'boxicons/css/boxicons.min.css'
import 'remixicon/fonts/remixicon.css'

import axios from '@/plugins/axios'
import { listPlan, payPlan, renewPlan } from '@/utilities/api'

export default {
  name: "MemberSubscriptionModify",
  data() {
    return {
      plans: [],
      showModal: false,
      showCheckoutModal: false,
      selectedPlan: null,
      selectedCoin: null,
      selectedPaymentType: null,
      coinSupports: [],
      additionalDomainNumber: 0,
      amountPrice: 0,
      subscription: {
        id: "",
        cname: "",
        czone: "",
        created: "",
        modified: "",
        next_bill_date: "",
        plan: {
          id: 1,
          name: ""
        },
        status: "",
        amount_price: "",
        additional_domain_number: 0,
        additional_traffic: 0
      },
      checkout: {
        cname: null,
        additional_domain_number: 0,
        additional_traffic: 0,
        amount_currency1: 0,
        plan: {
          name: null
        }
      }
    };
  },
  watch: {
    additionalDomainNumber() {
      if (this.selectedPlan)  {
        this.amountPrice = ((this.additionalDomainNumber * 30 * 30 / this.$moment().daysInMonth()) + this.selectedPlan.price.quantity).toFixed(6)
      }
    },
  },
  created() {
    this.fetchCoinSupport()
    listPlan().then(response => {
      this.plans = response.data
      this.fetchSubscription()
    }).catch((error) => {
      console.log(error)
    })
  },
  methods: {
    fetchCoinSupport() {
      axios.get('plan/coin-support/').then(response => {
        this.coinSupports = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    fetchSubscription() {
      axios.get(`plan/subscription/${this.$route.params.id}/`).then(response => {
        this.subscription = response.data
        this.clickPlan(this.subscription.plan)
        if ('DOMAIN' in this.subscription.add_meta) {
          this.additionalDomainNumber = this.subscription.add_meta.DOMAIN
        } else {
          this.additionalDomainNumber = 0
        }
      }).catch(error => {
        error.forEach(item => {
          this.flash(item, 'warning')
        })
      })
    },
    clickConfirm() {
      this.showCheckoutModal = false
      this.showModal = true
    },
    clickSubmit() {
      axios.patch(`plan/subscription/${this.$route.params.id}/modify/`, {
        plan: this.selectedPlan.id,
        additional_domain_number: this.additionalDomainNumber,
        additional_traffic: 0
      }).then(response => {
        if (response.data.is_increase) {
          this.checkout = response.data
          this.showCheckoutModal = true
        } else {
          if (response.data.is_increase === false) {
            this.flash(this.$i18n.t('subscription.DecreaseMessage'), 'success')
          }
          this.$router.push({name: 'DomainList'})
        }
      }).catch(error => {
        error.forEach(item => {
          this.flash(item, 'warning')
        })
      })
    },
    clickPlan(plan) {
      this.selectedPlan = plan
      this.amountPrice = plan.price.quantity
      this.amountPrice = ((this.additionalDomainNumber * 30 * this.$moment().diff(this.$moment(this.subscription.next_bill_date), 'days') / this.$moment().daysInMonth()) + this.amountPrice).toFixed(6)
      document.querySelectorAll('[id*=plan-]').forEach(item => {
        item.classList.remove('Selected')
      })
      const el = document.querySelector(`#plan-${plan.id}`)
      el.classList.add('Selected')
    },
    clickContactConsultant() {
      const el = document.querySelector('#contact')
      window.scroll({
        top: el.offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    },
    clickPay() {
      if (this.selectedPaymentType) {
        if (this.selectedPaymentType === 'cryptocurrency') {
          if (!this.selectedCoin) {
            this.flash(this.$t('checkout.WarningCoin'), 'warning')
            return
          }
        }
        if (this.selectedPaymentType === 'account_balance') {
          if (parseFloat(this.$store.state.balance) < parseFloat(this.$store.state.transaction.checkout.amount_currency1)) {
            this.flash(this.$t('checkout.WarningBalance'), 'warning')
            return
          }
          this.selectedCoin = 'USDT'
        }

        payPlan(this.checkout.bill_no, this.selectedCoin, this.selectedPaymentType).then(response => {
          this.showModal = false
          this.flash(this.$t('checkout.SuccessPaymentType'), 'success', {timeout: 4000});
          if (this.selectedPaymentType === 'cryptocurrency') {
            window.open(response.data.checkout_url, 'Checkout').focus()
          }
          if (this.selectedPaymentType === 'account_balance') {
            axios.get('plan/balance/').then(response => {
              this.$store.state.balance = response.data.amount
            }).catch(error => console.log(error))
          }
          this.$router.push({name: 'DomainList'})
        }).catch(error => console.log(error))
      } else {
        this.flash(this.$t('checkout.WarningPaymentType'), 'warning')
      }
    },
    clickCoins(coin, $event) {
      if (this.selectedPaymentType === 'cryptocurrency') {
        const li = document.querySelectorAll('ul#coins li')
        li.forEach(item => {
          item.classList.remove('Active')
        })
        const $target = $event.target
        $target.parentNode.classList.toggle('Active')
        this.selectedCoin = coin
      }
    },
  },
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}

.pricing h4 {
  font-size: 46px;
  color: #5a5af3;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding-bottom: 12px;
}

.pricing ul i {
  color: #5a5af3;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .btn-buy {
  background: #434175;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 50px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  transition: 0.3s;
  border: none;
      margin-top: 30px;
}

.pricing .btn-buy:hover {
  background: #5a5af3;
}

.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}

.pricing .featured .btn-buy {
  background: #5a5af3;
}

.pricing .featured .btn-buy:hover {
  background: #7272f5;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #eeeef5;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #434175;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #5a5af3;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
  background: #fff;
}

.contact .info-box i {
  font-size: 32px;
  color: #5a5af3;
  border-radius: 50%;
  padding: 8px;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  background: #fff;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 5px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #5a5af3;
}

.contact .php-email-form input {
  padding: 20px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #5a5af3;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #7272f5;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mt-10 {
  margin-top: 10px;
}

.btn-buy {
  cursor: pointer;
}

div.Selected {
  border: 2px solid blue;
}

div.price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

span#price {
  font-size: 1.5em;
  display: block;
}

ul#coins {
  padding: 0;
  list-style: none;
}

ul#coins.Disabled li {
  border: unset !important;
  background-color: #eee;
}

ul#coins li {
  position: relative;
  display: inline-block;
  padding: 10px;
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}

ul#coins li.Active {
  border: 1px solid #0064bd;
}

ul#coins li div.box {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
}

ul#coins li img {
  width: 100px;
  height: 100px;
}

ul#coins p {
  margin: 0;
  text-align: center;
}
.BalanceDanger {
  color: #e55353;
}
</style>
